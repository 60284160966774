import React, { useEffect, useState } from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useTranslation, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { EXPERIMENTS, NO_BREAK_SPACE } from '../../../../../constants';
import { useAdaptedSettings, DynamicTaglineProvider } from '../../../../../hooks';
import { getPlanValidityCycle } from '../../../../../utils';
import { orderPlansForMobileView } from '../../../../../utils/orderPlansForMobileView';
import { Plan } from '../Plan';
import { classes } from './PlanList.st.css';

// Max item width + margins;
export const MAX_ITEM_WIDTH = 454 + 60;

export interface PlanListProps {
  plans: PublicPlan[];
  showDemoHighlight?: boolean;
  expandMobileBenefits: boolean;
  selectPlan(plan: PublicPlan): void;
  selectedPlanId?: string;
}

export const PlanList: React.FC<PlanListProps> = ({
  plans,
  showDemoHighlight,
  selectPlan,
  expandMobileBenefits,
  selectedPlanId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const settings = useAdaptedSettings();
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const { experiments } = useExperiments();
  const isLoaderEnabled = experiments.enabled(EXPERIMENTS.LOADERS);

  const highlightedPlanId = settings.getHighlightedPlanId(plans, showDemoHighlight);
  const orderedPlans = isMobile && highlightedPlanId ? orderPlansForMobileView(plans, highlightedPlanId) : plans;

  const recurringPlansExist = orderedPlans.some((p) => p.pricing?.subscription);
  const freeTrialDaysExist = orderedPlans.some((p) => p.pricing?.freeTrialDays);
  const taglinesExist = orderedPlans.some((p) => p.description);
  const validityCyclesExist = orderedPlans.some((p) => getPlanValidityCycle(p, t) !== NO_BREAK_SPACE);
  const anyBenefits = orderedPlans.some((p) => p.perks?.values?.length);

  const maxWidth = MAX_ITEM_WIDTH * orderedPlans.length;

  const onSelectPlan = (plan: PublicPlan) => {
    setIsLoading(true);
    selectPlan(plan);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isLoading) {
      /* 
        Reset loading state automatically after 5 seconds
        in case checkout is on the same page as PlanList widget
        and component isn't destroyed on navigation
      */
      timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <DynamicTaglineProvider>
      <ul className={classes.container} style={isEditorX ? {} : { maxWidth }}>
        {orderedPlans.map((plan) => (
          <li key={plan.id} data-hook="plan">
            <Plan
              expandMobileBenefits={expandMobileBenefits}
              anyBenefits={anyBenefits}
              highlighted={highlightedPlanId === plan.id}
              plan={plan}
              recurringPlansExist={recurringPlansExist}
              freeTrialDaysExist={freeTrialDaysExist}
              validityCyclesExist={validityCyclesExist}
              taglinesExist={taglinesExist}
              onClick={() => onSelectPlan(plan)}
              loading={isLoaderEnabled && isLoading && plan.id === selectedPlanId}
            />
          </li>
        ))}
      </ul>
    </DynamicTaglineProvider>
  );
};
